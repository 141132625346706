// {
//   path: '/admin_manage/overview',  // 路由
//     name: 'admin',  // 路由别名，供this.$router({ name: 'admin' })跳转  请保持name唯一性
//     component: () => import( //文件路径 ) // 模板文件
//       meta: {
//       menuName: '概述', // 菜单名称
//       role: 'admin',  // 权限点
//       roleId: '', // 权限点id
//       icon: 'URL', // icon 图片
//       activeIcon: '', // 高亮时，icon 图片
//       outLink: false // 是否为外部链接
//       hideInMenu: false // 是否在菜单中展示
//     },
//       children: [
//       // 子路由，参数同父路由一致
//     ]
// }

import administration from './modules/administration'; // 首页
import arrangeCenter from './modules/arrange-center.js'; // 布置中心
import data from './modules/data'; // 数据中心
import homeAndSchool from './modules/home-and-school'; // 家校共育
import homeworkManage from './modules/homework-manage'; // 假期作业
import liveCourse from './modules/liveCourse'; // 直播开课
import managementCenter from './modules/management-center'; // 管理中心
import psyChologyCenter from './modules/psychology-center'; // 德育中心
import schoolRunning from './modules/school-running'; // 学校办学
import teachingCenter from './modules/teaching-center'; // 教学中心
import weekTrain from './modules/week-train'; // 周培优
// 评价系统
import evaluationSystem from './modules/evaluationSystem';

export const noAuthRoutes = [
  {
    path: '/sxyms',
    name: 'sxyms',
    component: () => import('@/views/index/sxyms.vue'),
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/views/index/entry.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/entry.vue'),
  },
  {
    path: '/paper_export',
    name: 'paper_export',
    component: () => import('@/views/paper_export/entry.vue'),
  },
  {
    path: '/system_info',
    name: 'system_info',
    component: () => import('@/views/system_info/entry.vue'),
  },

  {
    path: '/help-center',
    name: 'help-center',
    component: () => import('@/views/help-center/entry.vue'),
  },

  {
    path: '/weekQuestion',
    name: 'weekQuestion',
    component: () =>
      import('@/views/week-train/aiCourse/weekQuestion/entry.vue'),
  },
  {
    path: '/exportWrongQuestion',
    name: 'exportWrongQuestion',
    component: () =>
      import(
        '@/views/week-train/learningAnalysis/exportWrongQuestion/entry.vue'
      ),
  },
  {
    path: '/printPsyConsultRecord',
    name: 'printPsyConsultRecord',
    component: () =>
      import('@/views/psychology-center/consult/record/print/entry.vue'),
  },

  {
    path: '*',
    name: '404',
    component: () => import('@/not-found.vue'),
  },
];

// 需要校验的路由,需要loginLayout包裹的页面
export const authRoutes = [
  ...administration,
  ...teachingCenter,
  ...homeworkManage,
  ...liveCourse,
  ...psyChologyCenter,
  ...homeAndSchool,
  ...schoolRunning,
  ...data,
  ...weekTrain,
  ...managementCenter,
  ...evaluationSystem,
  ...arrangeCenter,
  // 好课评选
  {
    path: '/goodClassActivity',
    name: 'goodClassActivity',
    component: () => import('@/views/goodClassActivity/entry.vue'),
    meta: {
      hideInMenu: true,
      hasSideMenu: false,
      role: '',
      roleId: '',
    },
  },
];

// 需要校验的路由,不需要loginLayout包裹的页面
export const authRoutesNoLoginLayout = [
  {
    path: '/dataPanelPreview',
    name: 'dataPanelPreview',
    component: () => import('@/views/data-panel-preview/entry.vue'),
    meta: {
      role: 'dataCenter.evaluation.list',
      roleId: '11020',
      needQueryHelpCenter: false,
      needGuide: false,
      hideInMenu: true,
    },
  },
];
